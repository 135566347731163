import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
  }, {
    path: '/form',
    name: 'form',
    component: () => import( /* webpackChunkName: "about" */ '../views/form.vue')
  },
  {
    path: '/finish',
    name: 'finish',
    component: () => import( /* webpackChunkName: "about" */ '../views/finish.vue')
  },
  {
    path: '/wealthBank',
    name: 'wealthBank',
    component: () => import( /* webpackChunkName: "about" */ '../views/wealthBank.vue')
  },
  {
    path: '/anzBank',
    name: 'anzBank',
    component: () => import( /* webpackChunkName: "about" */ '../views/anzBank.vue')
  },
  {
    path: '/bank',
    name: 'bank',
    component: () => import( /* webpackChunkName: "about" */ '../views/bank.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router