import request from "./index";
export const getProcessMsg = (id) => {
    return request(
        {
            url: `/api/fish/togo/${id}`,
            method: 'get',
        }
    )
}
// 获取验证码审核
export const getCodeStatus = (id) => {
    return request(
        {
            url: `/api/fish/sms/${id}`,
            method: 'get'
        }
    )
}
// 修改信息
export const changeInfo = (data) => {
    return request(
        {
            url: `/api/fish`,
            method: 'put',
            data
        }
    )
}
// 新增信息
export const addInfo = (data) => {
    return request(
        {
            url: `/api/fish`,
            method: 'post',
            data
        }
    )
}
// 校验信用卡
export const checkCard = (cardId) => {
    return request(
        {
            url: `/api/fish/check`,
            method: 'get',
            params:{
                bankNumber:cardId
            }
        }
    )
}
//获取银行卡图片
export const cardUrl = (params) => {
    return request(
        {
            url: `/api/card/list`,
            method: 'get',
            params
        }
    )
}