<template>
  <div class="nav">
    <div class="nav_left">
      <img src="../assets/expend.jpg" alt="" />
    </div>
    <div class="nav_right">
      <img class="logo" src="../assets/logo1.jpg" alt="" />
      <van-icon name="question" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.nav {
  display: flex;
  height: 80px;
  line-height: 80px;
  /* background: #000; */
}
.nav_left {
  flex: 1;
  /* background: red; */
  vertical-align: middle;
  border-right:1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
}
.nav_left img{
  width: 30px;
}
.nav_right {
  flex: 8;
  /* background: yellow; */
  display: flex;
  justify-content: space-between;
  padding: 0 10px 0 20px;
   border-bottom: 1px solid #e9e9e9;
  align-items: center;
}
.nav_right .logo {
  width: 120px;
  height: 45px;

}
.nav_right .van-icon {
  vertical-align: middle;
  line-height: 80px;
  font-size: 28px;
}
</style>