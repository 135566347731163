import axios from 'axios';
const request = axios.create({
    // baseURL: 'http://111.229.67.72:8893',
    baseURL: '/api',
    timeout: 15000
})
request.interceptors.request.use(
    (config) => {
        console.log(config)
        return config
    },
    (error) => {
        console.log(error)
        return error
    }
)
request.interceptors.response.use((res) => {
    console.log(res)
    return res.data
},
    (err) => {
        console.log(err)
        return err
    }
)
export default request