<template>
  <div class="info">
    <p>Dear Customer:</p>
    <p>You have an e-toll payment failed</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.info {
  font-size: 24px;
  text-align: left;
  font-weight: 600;
  padding-left: 10px;
  border-bottom: 1px solid #e9e9e9;
  line-height: 60px;
}
</style>