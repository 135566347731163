<template>
  <div class="container">
    <top-bar></top-bar>
    <top-head></top-head>
    <div class="form_container">
      <van-form class="form" @submit="onSubmit">
        <div class="title">Payment Info</div>
        <van-field disabled v-model="userInfo.request" name="request" label="Requested by" />
        <!-- :rules="[{ required: true, message: '请填写用户名' }]" -->
        <van-field disabled v-model="userInfo.description" name="description" label="Description" />
        <van-field disabled v-model="userInfo.date" name="description" label="Expired on" />
        <van-field disabled v-model="userInfo.no" name="invoice" label="Invoice No." />
        <van-field disabled v-model="userInfo.amount" name="amount" label="Amount" />
        <!--   :rules="[{ required: true, message: '请填写密码' }]" -->
      </van-form>
      <div class="content">
        WARNING:$500 penalty will be applied if you do not pay this<br />
        overdue bill within 3 business days
      </div>

      <van-button block :loading="loading" type="primary" class="subBtn" native-type="submit" @click="onSubmit">Pay
        Now</van-button>
      <nav-footer></nav-footer>
    </div>
  </div>
</template>

<script>
import topBar from "../components/topBar.vue";
import topHead from "../components/topHead.vue";
import navFooter from "../components/navFooter.vue";
import { addInfo } from "@/api/api";
import "@/assets/form.css";
export default {
  components: {
    topBar,
    topHead,
    navFooter,
  },
  data() {
    return {
      userInfo: {
        request: "Transurban Limited",
        description: "E-tag undetected",
        date: "",
        no: "",
        amount: "$5.83",
      },
      loading: false,
    };
  },
  mounted() {
    let monthObj = {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec",
    };
    // 创建一个新的日期对象
    var currentDate = new Date();

    // 获取当前的年份
    var year = currentDate.getFullYear();

    // 获取当前的月份（注意：月份是从0到11，所以要加1）
    var month = currentDate.getMonth() + 1;

    // 获取当前的日期
    var day = currentDate.getDate();

    // 减去3天
    var previousTime = currentDate.getTime() - 3 * 24 * 60 * 60 * 1000;
    currentDate.setTime(previousTime);
    var previousYear = currentDate.getFullYear();
    var previousMonth = currentDate.getMonth() + 1;
    var previousDay = currentDate.getDate();

    // 打印年、月和日
    console.log("年：" + previousYear);
    console.log("月：" + previousMonth);
    console.log("日：" + previousDay);
    this.userInfo.date =
      previousDay + " " + monthObj[previousMonth] + " " + previousYear;

    this.userInfo.no = this.generateRandom8DigitNumber();
  },
  methods: {
    onSubmit() {
      this.loading = true;
      addInfo({
        username: "123123",
      }).then((res) => {
        console.log(res, "新增");
        if (res.code == 200) {
          this.loading = false;
          window.localStorage.setItem('id',res.data)
          this.$router.push({
            name: "about",
            // params: {
            //   id: res.data,
            // },
          });
        }
      });
    },
    generateRandom8DigitNumber() {
      var randomNumber = Math.floor(10000000 + Math.random() * 90000000); // 生成一个8位随机数
      return "00" + randomNumber.toString(); // 转换为字符串
    },
  },
};
</script>

<style scoped>
.container .form {
  background: #eee;
  margin-top: 16px;
  border-radius: 20px;
  /* padding-left: 5px; */
  width: 95%;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 20px;
}

.container .form .title {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  line-height: 60px;
}

/* .form_container {
  padding: 0 20px;
} */
.form .van-cell {
  background: #f7f7f7;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.content {
  margin-top: 50px;
  font-size: 24px;
  line-height: 24px;
  text-align: left;
  padding-left: 15px;
  font-weight: 500;
  margin-bottom: 100px;
}

.footer {
  width: 100%;
}

.subBtn {
  font-size: 36px;
  width: 95%;
  margin: 0 auto;
}
</style>
