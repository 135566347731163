<template>
  <div class="foote">
    <div class="footBox">
      <div class="footLog">
        <div class="text">Owned and managed by</div>
        <div class="footImg">
          <img src="../assets/logo-transurban.png" alt="" />
        </div>
        <div class="footCenter">
          <div class="footTop">
            <div class="footUlBoxT">Terms of use</div>
            <div class="footUlBoxT">Terms of usePrivacy</div>
            <div class="footUlBoxT">Contact us</div>
            <div class="footUlBoxT">Help</div>
            <div class="footUlBoxT">About Us</div>
          </div>
          <div class="footBot">
            Linkt and Linkt Sydney are trade marks of Transurban Limited used
            under licence by Tollaust Pty Ltd ABN 37 050 538 693.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.foote{
  margin-top: 40px;
}
.footLog {
  padding-top: 100px;
  background: #26272b;
  /* height: 30vh; */
}
.footLog .text {
  font-size: 26px;
  color: #9da1a7;
  text-align: left;
  padding-left: 60px;
}
.footLog .footImg {
  text-align: left;
  padding-left: 80px;
}
.footLog .footImg img {
  width: 200px;
}
.footCenter {
  /* font-size: 20px; */
  color: #b8b7b4;
  text-align: left;
}
.footCenter .footTop{
  padding-left: 80px;
  font-size: 24px;
  line-height: 40px;
}
.footBot{
  padding: 0 40px 0 80px;
  margin-top: 30px;
    color: #b8b7b4;
    font-size: 22px;
}
</style>